.services-container {
  padding: 213px 0 219px;
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.service {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  gap: 25px;
}

.service img {
  width: 100px;
  object-fit: contain;
  flex-shrink: 0;
}

.service .service-content {
  width: 480px;
}

.service .service-content h2 {
  font-size: 36px;
  line-height: 39px;
  font-weight: 500;
  color: #F47421;
  margin: 0 0 20px;
}

.service .service-content p {
  font-size: 24px;
  line-height: 26px;
  font-weight: 400;
  color: #3F3D56;
  margin: 0;
}

.service-row {
  display: flex;
  justify-content: space-between;
  gap: 75px;
}

@media screen and (max-width: 1650px) {
  .service-img {
    width: 15%;
  }

  .service-content {
    width: 100%!important;
  }
}

@media screen and (max-width: 900px) {
  .services-container {
    padding: 120px 0 120px;
    gap: 75px;
  }

  .service-row {
    flex-direction: column;
  }
}

@media screen and (max-width: 390px) {
  .service {
    flex-direction: column;
    align-items: baseline;
  }

  .service img {
    width: 110px;
  }

  .service-content {
    margin-left: 0!important;
  }
}