.intro-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 165px 0 148px;
}

.intro-txt h1 {
  font-size: 64px;
  line-height: 70px;
  font-weight: 300;
  color: #2F2E41;
  font-style: italic;
  margin-top: 0;
  margin-bottom: 60px;
}

.intro-txt span {
  font-weight: 600;
  color: #FFA200;
  font-style: normal;
}

.img-txt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;
  gap: 25px;
}

.img-txt .img-container {
  width: 125px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
}

.img-txt .img-container img {
  height: auto;
}

.img-txt p {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

.intro-txt {
  padding-right: 50px;
}

.intro-txt a {
  text-decoration: none;
}

.intro-txt a button {
  width: 250px;
  height: 50px;
  border-radius: 50px;
  border: none;
  background-color: #F47421;
  color: #fff;
  font-size: 20px;
  line-height: 21px;
  font-weight: 700;
  border: 1px solid #F47421;
}

.intro-txt a button:hover {
  color: #F47421;
  background-color: white;
}

.small-pic {
  display: none;
}

@media screen and (max-width: 1500px) {
  .intr-pic {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .intro-txt {
    padding-right: 0px;
  }
  
  .intro-container {
    flex-direction: column;
    padding: 50px 0 70px;
  }

  .intr-pic {
    display: none;
  }

  .small-pic {
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 390px) {
  .img-txt {
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 55px;
    gap: 0;
  }

  .img-txt img {
    margin-bottom: 15px;
  }
}