.sub-main-container {
  width: 1600px;
  margin: auto;
}

.subs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 230px 0 245px;
}

.subs-details {
  width: 50%;
}

.subscriptions-container {
  width: 50%;
}

.subs-details h2 {
  font-size: 64px;
  font-weight: 500;
  line-height: 70px;
  margin: 23px 0 43px;
}

.subs-details p {
  font-size: 32px;
  font-weight: 500;
  line-height: 35px;
  margin: 0;
}

.subscriptions-container .sub-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscriptions {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
}

.subscriptions .sub-head {
  background: #3A577F;
  border-radius: 25px 25px 0px 0px;
  padding: 25px 35px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.subscriptions .sub-head h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 35px;
  margin: 0;
}

.subscriptions .sub-head span {
  font-size: 32px;
  font-weight: 500;
  line-height: 35px;
}

.subscriptions .sub-inner {
  padding: 36px 35px 25px;
}

.subscriptions .sub-inner h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 32px;
}

.subscriptions .sub-inner p {
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  margin: 0 0 27px;
}

.customer {
  margin: 0 0 40px;
}

.subscriptions .sub-inner a {
  text-decoration: none;
}

.subscriptions .sub-inner a button {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: #fff;
  width: 319px;
  height: 50px;
  background-color: #3A577F;
  border-radius: 50px;
  border: 1px solid #3A577F;
}

.subscriptions .sub-inner a button:hover {
  background-color: white;
  color: #3A577F;
}

.subscription-silver {
  margin-left: 30px;
}

.subscription-silver .sub-head {
  background-color: #FAA81B;
}

.subscription-silver .sub-inner a button {
  background-color: #FAA81B;
  border-color: #FAA81B;
}

.subscription-silver .sub-inner a button:hover {
  color: #FAA81B;
}

.subscription-gold .sub-head {
  background-color: #F47421;
  margin-top: 30px;
  width: 100%;
}

.subscription-gold .sub-inner a button {
  background-color: #F47421;
  border-color: #F47421;
}

.subscription-gold .sub-inner a button:hover {
  color: #F47421;
}

.subscription-gold .sub-inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.small-screen {
  display: none;
}

@media screen and (max-width: 1650px) {
  .sub-main-container {
    width: 1200px;
  }

  .subs-details h2 {
    margin: 10px 0 20px;
  }

  .blog-img {
    width: auto;
  }
  
  .subscriptions .sub-head {
    padding: 15px 25px;
  }
  
  .subscriptions .sub-head span {
    font-size: 24px;
  }
  
  .subscriptions .sub-inner {
    padding: 25px 25px 20px;
  }
  
  .subscriptions .sub-inner h3 {
    margin: 0 0 15px;
  }
  
  .subscriptions .sub-inner p {
    margin: 0 0 13px;
  }

  .subscriptions .sub-inner a button {
    font-size: 14px;
    line-height: 18px;
    height: 40px;
  }
}

@media screen and (max-width: 1250px) {
  .sub-main-container {
    width: 900px;
  }

  .subs-container {
    flex-direction: column;
  }

  .subs-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
  }

  .subscriptions-container {
    width: 100%;
  }

  .small-screen {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  .sub-main-container {
    width: 576px;
  }
}

@media screen and (max-width: 600px) {
  .sub-main-container {
    width: 380px;
  }
}

@media screen and (max-width: 400px) {
  .sub-main-container {
    width: auto;
    padding: 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .subs-container {
    padding: 120px 0 110px;
  }

  .sub-row {
    flex-direction: column;
  }

  .subscriptions {
    margin-bottom: 20px;
  }

  .subscription-silver {
    margin-left: 0;
    margin-bottom: 0;
  }

  .subscription-gold .sub-inner {
    flex-direction: column;
  }

  .subscription-gold .sub-inner a {
    width: 100%;
  }
}