.footer-container {
  background: #FFFFFF;
  box-shadow: 0px -6px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 182px 0 0;
}

.footer-container img {
  width: 305px;
  height: 63px;
  margin-bottom: 50px;
}

.footer-container ul {
  padding: 0;
  margin: 115px 0 177px;
}

.footer-container li {
  list-style-type: none;
  margin-right: 75px;
  display: inline-block;
}

.footer-container li:last-child {
  margin-right: 0;
}

.footer-container li a {
  text-decoration: none;
  font-size: 32px;
  line-height: 34px;
  color: #FAA81B;
  font-weight: 500;
}

.footer-container p {
  margin-top: 1rem;
  font-size: 20px;
  line-height: 22px;
  color: #000;
  font-weight: 500;
}

@media screen and (max-width: 1650px) {
  .footer-container li a {
    font-size: 24px;
    line-height: 28px;
  }

  .footer-container p {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 576px) {
  .footer-container ul li {
    display: block;
    margin-bottom: 36px;
    margin-right: 0;
  }

  .footer-container ul {
    margin: 98px 0 84px;
  }

  .footer-container img {
    width: 245px;
  }
}