@font-face {
  font-family: archivo;
  src: url(../public/assets/fonts/Archivo-Regular.ttf);
}

body {
  margin: 0;
  font-family: archivo;
}

.main-container {
  width: 1450px;
  margin: auto;
}

@media screen and (max-width: 1650px) {
  .subscriptions .sub-inner a button {
    min-width: 200px;
    width: 100%!important;
    margin: auto;
  }

  h1 {
    font-size: 36px!important;
    line-height: 50px!important;
  }

  h2 {
    font-size: 24px !important;
    line-height: 30px!important;
  }

  h3 {
    font-size: 24px !important;
    line-height: 30px!important;
  }

  p {
    font-size: 16px!important;
    line-height: 22px!important;
  }
}

@media screen and (max-width: 1500px) {
  .main-container {
    width: 1200px;
  }
}

@media screen and (max-width: 1250px) {
  .main-container {
    width: 900px;
  }
}

@media screen and (max-width: 900px) {
  .main-container {
    width: 576px;
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    width: 380px;
  }
}

@media screen and (max-width: 400px) {
  .main-container {
    width: auto;
    padding: 0 30px;
  }
}

button {
  transition: 0.25s;
}