.title-container {
  background-color: #F47421;
  padding: 60px 0;
  text-align: center;
}

.title-container h2 {
  font-size: 64px;
  line-height: 70px;
  color: #fff;
  text-align: center;
  margin: 0 0 30px;
  font-weight: 500;
}

.title-container a {
  text-decoration: none;
}

.title-container button {
  width: 375px;
  height: 50px;
  border-radius: 50px;
  background-color: #fff;
  border: none;
  font-size: 20px;
  color: #F47421;
  line-height: 22px;
  border: 1px solid white;
}

.title-container button:hover {
  background: #F47421;
  color: white;
}

@media screen and (max-width: 1650px) {
  .title-container button {
    width: 280px;
    height: 40px;
    font-size: 16px;
  }
}