.challenge-container{
    background: linear-gradient(180deg, #FDCF0B 0%, #F47421 53.12%, #A54623 100%);
    padding: 269px 0 253px;
}

.challenge-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.challenge-txt{
    width: 650px;
    color: #fff;
}

.challenge-txt h2{
    font-size: 64px;
    line-height: 70px;
    font-weight: 500;
    margin: 0 0 30px;
}

.challenge-txt h3{
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;
    margin: 0 0 30px;
}

.challenge-txt p{
    font-size: 24px;
    line-height: 26px;
    font-weight: 300;
    margin: 0;
}

.alt-challenge{
    flex-direction: row-reverse;
    margin-top: 263px;
}

@media screen and (max-width: 1500px) {
    .course-img{
        width: 50%;
    }
    .challenge-img{
        width: 50%;
    }
}

@media screen and (max-width: 900px) {
    .challenge-content{
        flex-direction: column;
    }
    .challenge-content img{
        margin-bottom: 40px;
    }
    .challenge-container{
        padding: 134px 0;
    }
    .alt-challenge{
        margin-top: 170px;
    }
    .challenge-txt{
        width: 100%;
    }
}